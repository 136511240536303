<template>

  <div class="pbf" v-if="edit_data">

     <div v-if="edit_data">
       
       <n-message-provider>
         <EditNutrientScheme
          :edit_data="edit_data"
          />
       </n-message-provider>
     </div>

   </div>
     
</template>

<script setup>

import { NMessageProvider } from 'naive-ui'

import EditNutrientScheme from '@/pages/brands-manager/components/EditNutrientScheme'

const {$api} = useNuxtApp();
const route = useRoute();

const props = defineProps({
  data: {
    type: Object    
  }
});

const loadData = async function() { 
  const response = await $api.getB2BProductScheme(route.params['id'],route.query['tmp_id']);
  return response; 
}

const { pending: is_loading,  data: edit_data } = await useLazyAsyncData('edit_data', async () => await loadData())

useHead(useNuxtApp().$head.getBrandManager());

onUnmounted(() => {
  edit_data.value = null;
})

/*

import EditNutrientScheme from '@/pages/brands-manager/components/EditNutrientScheme'

export default {
  components: {
    EditNutrientScheme
  },
  data () {
    return {
      edit_data: {}
    }
  },
  created() {
    this.loadResults();
  },
  computed: {
  },
  methods: {
    loadResults(){
      this.$axios.get('/brands-manager/schemes/edit/' + this.$route.params['id'] + (this.$route.query['tmp_id'] ? '?tmp_id=' + this.$route.query['tmp_id'] : ''), {
        params: {
          
        }
      })
      .then((response) => {        
        if(response.status === 200){
          this.edit_data = response.data.data;
        }
      })
      .catch(function (error) { 
        
      });
    }
  }
}*/
</script>

<style scoped>



/**/
 
</style>

<style>
 
</style>